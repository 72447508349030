import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PatientSelectors, ProfileSelectors } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/analytics.type';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';
import { DatePipe } from '@app/shared/pipes';

@Injectable()
export class ChartVariablesService {
  private currentTab = new Subject<any>();
  public currentTab$ = this.currentTab.asObservable();

  constructor(
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private datePipe: DatePipe,
    private analytics: AnalyticsService,
  ) {}

  broadcastTabChange(tab) {
    this.currentTab.next(tab);
  }

  populate(
    value: string,
    template: Template,
    insertionEventProps: Partial<TrackEventProperties>,
  ): Observable<string> {
    return combineLatest([
      this.patientSelectors.patient,
      this.profileSelectors.profile,
    ]).pipe(
      take(1),
      map(results => {
        let transformedValue = value;
        const patient = results[0];
        const profile = results[1];
        const now = new Date();
        const patientTimezone = this.datePipe.getTimeZone(
          now,
          patient.timezone,
        );
        const patientPcp = patient.pcp || 'one of our primary care providers';
        const patientOffice = patient.office ? patient.office.name : '';
        const patientFullName = `${patient.firstName} ${patient.lastName}`;
        const userDate = this.datePipe.transform(now, '4y');
        const userTime = `${this.datePipe.transform(
          now,
          '12Time',
        )} ${this.datePipe.getTimeZone(now)}`;

        const variables = {
          '\\.patient_firstname': patient.firstName,
          '\\.patient_lastname': patient.lastName,
          '\\.last_name': patient.lastName,
          '\\.fullname': patientFullName,
          '\\.patient_fullname': patientFullName,
          '\\.preferred_name': patient.preferredName,
          '\\.patient_preferred_name': patient.preferredName,
          '\\.pcp': patientPcp,
          '\\.patient_pcp': patientPcp,
          '\\.brand': patient.brand,
          '\\.office': patientOffice,
          '\\.patient_office': patientOffice,
          '\\.website': patient.website,
          '\\.my_first_name': profile.firstName,
          '\\.user_firstname': profile.firstName,
          '\\.my_last_name': profile.lastName,
          '\\.my_name': profile.displayName,
          '\\.user_fullname': profile.displayName,
          '\\.my_title': profile.displayNameWithSuffix,
          '\\.user_title': profile.displayNameWithSuffix,
          '\\.my_role': profile.suffixRole,
          '\\.user_role': profile.suffixRole,
          '\\.my_npi': profile.npi || '',
          '\\.user_npi': profile.npi || '',
          '\\.todays_date': userDate,
          '\\.user_date': userDate,
          '\\.current_time': userTime,
          '\\.user_time': userTime,
          '\\.patient_date': this.datePipe.transform(
            now,
            '4y',
            patientTimezone,
          ),
          '\\.patient_time':
            this.datePipe.transform(now, '12Time', patientTimezone) +
            ' ' +
            patientTimezone,
        };

        Object.keys(variables).forEach(varName => {
          transformedValue = transformedValue.replace(
            new RegExp(varName, 'g'),
            variables[varName],
          );
          if (transformedValue !== value) {
            this.trackAnalyticsEvent(
              varName.substring(1),
              template,
              insertionEventProps,
            );
          }
        });

        return transformedValue;
      }),
    );
  }

  private trackAnalyticsEvent(
    variableName: string,
    template: Template,
    insertionEventProps: Partial<TrackEventProperties>,
  ) {
    this.analytics.track(AnalyticsEvent.VariableInserted, {
      workflow: 'Charting',
      variableName: variableName,
      templateId: template.id,
      templateName: template.name,
      templateType: template.internal_user_id ? 'Personal' : 'Public',
      ...insertionEventProps,
    });
  }
}
