import { NgModule } from '@angular/core';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { environmentConfig } from '@environments/environment-config';
import { CookieService } from './shared/cookie.service';
import { LoginGuard } from './shared/login-guard';
import { LogoutGuard } from './shared/logout.guard';

@NgModule({
  imports: [
    Auth0Module.forRoot(environmentConfig.auth0),
  ],
  providers: [CookieService, LoginGuard, LogoutGuard],
})
export class AuthModule {
  constructor() {}
}
