import { AbstractRx } from './pdmp.type';

export const mapToPdmpNonParticipatingStates = ({
  data: { pdmpNonParticipatingStates },
}) => pdmpNonParticipatingStates.map(({ shortName }) => shortName);

export const mapToRxCartReadyToSignResult = ({
  data: {
    markRxCartReadyToSign: { success },
  },
}): boolean => success;

// replace last comma with ampersand. eg. CA, IL, NY -> CA, IL & NY
export const formatStateList = (states: string[]) =>
  states.join(', ').replace(/,([^,]*)$/, ' &' + '$1');

export const hasControlledSubstanceFilter = (rx: AbstractRx) =>
  rx.dispensableControlledSubstance;
