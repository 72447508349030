import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {
  combineLatest,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
  take,
} from 'rxjs';

import { FullPrescriptionHistoryComponent } from '@app/features/prescription-history/components/full-prescription-history/full-prescription-history.component';
import { PrescriptionHistoryTabs } from '@app/features/prescription-history/shared/prescription-history.type';
import { PatientPharmacySelectors } from '@app/modules/pharmacy-picker/store/patient-pharmacy.selectors';
import { DialogService } from '@app/shared/components/dialog';
import { filterTruthy } from '@app/utils';

import {
  AbstractRx,
  PdmpNonParticipatingStatesResponse,
} from '../../shared/pdmp.type';
import {
  formatStateList,
  hasControlledSubstanceFilter,
  mapToPdmpNonParticipatingStates,
  mapToRxCartReadyToSignResult,
} from '../../shared/pdmp.utils';

export const markRxCartReadyToSignMutation = gql`
  mutation MarkRxCartReadyToSign($rxCartId: ID!) {
    markRxCartReadyToSign(input: { rxCartId: $rxCartId }) {
      success
    }
  }
`;

export const pdmpNonParticipatingStatesQuery = gql`
  query GetPdmpNonParticipatingStates {
    pdmpNonParticipatingStates {
      id
      shortName
    }
  }
`;

@Component({
  selector: 'omg-pdmp-dialogue-banner',
  templateUrl: './pdmp-dialogue-banner.component.html',
  styleUrls: ['./pdmp-dialogue-banner.component.scss'],
})
export class PdmpDialogueBannerComponent implements OnInit, OnChanges {
  @Input() cartId: number;
  @Input() cartItems: AbstractRx[];
  @Output() confirmedReportViewed = new EventEmitter<boolean>();

  isChecked: boolean;

  items$ = new ReplaySubject<AbstractRx[]>(1);
  requiredStates$: Observable<string>;
  reportStates$: Observable<string>;
  nonReportStates$: Observable<string>;

  constructor(
    private dialogService: DialogService,
    private apollo: Apollo,
    private patientPharmacySelectors: PatientPharmacySelectors,
  ) {}

  ngOnInit(): void {
    this.setupListeners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // reset PDMP confirmation if the number of controlled substances in rxCart changes
    const itemsChanges = changes['cartItems'];
    if (itemsChanges.previousValue) {
      const previousCount = itemsChanges.previousValue.filter(
        hasControlledSubstanceFilter,
      ).length;
      const currentCount = itemsChanges.currentValue.filter(
        hasControlledSubstanceFilter,
      ).length;

      if (previousCount !== currentCount) {
        this.isChecked = false;
      }
    }
    this.items$.next(itemsChanges.currentValue);
  }

  onShowReportClick(): void {
    const dialogRef = this.dialogService.open(
      FullPrescriptionHistoryComponent,
      {
        autoFocus: true,
        disableClose: false,
      },
    );

    if (dialogRef) {
      // Change this to use @Inject(OMG_DIALOG_DATA)
      dialogRef.componentInstance.currentHistoryTab =
        PrescriptionHistoryTabs.pdmp.id;
      dialogRef.componentInstance.rxCartId = this.cartId;
    }
  }

  onConfirmReportViewedChange(): void {
    if (this.isChecked) {
      this.markAsReadyToSign(this.cartId)
        .pipe(take(1))
        .subscribe(result => {
          this.isChecked = result;
          this.confirmedReportViewed.emit(this.isChecked);
        });
    } else {
      this.confirmedReportViewed.emit(false);
    }
  }

  private setupListeners(): void {
    const statesAndNonParticipation$ = combineLatest([
      this.controlledSubstanceStates$,
      this.nonParticipatingStates$,
    ]);

    this.reportStates$ = statesAndNonParticipation$.pipe(
      map(([states, nonParticipatingStates]) =>
        states.filter(state => !nonParticipatingStates.includes(state)),
      ),
      map(formatStateList),
    );
    this.nonReportStates$ = statesAndNonParticipation$.pipe(
      map(([states, nonParticipatingStates]) =>
        states.filter(state => nonParticipatingStates.includes(state)),
      ),
      map(formatStateList),
    );
    this.requiredStates$ = this.controlledSubstanceStates$.pipe(
      map(formatStateList),
    );
  }

  private get controlledSubstanceStates$(): Observable<string[]> {
    return this.items$.pipe(
      switchMap(items =>
        combineLatest(
          items.filter(hasControlledSubstanceFilter).map(
            item =>
              'pharmacy' in item
                ? of(item.pharmacy) //From abstract rx
                : this.getPharmacy$(item.pharmacyId), //From patient pharmacy
          ),
        ),
      ),
      map(pharmacies => pharmacies.map(({ address }) => address.state)),
      map(states => [...new Set(states)].sort()), // To unique states
    );
  }

  private get nonParticipatingStates$(): Observable<string[]> {
    return this.apollo
      .use('onelife')
      .query<PdmpNonParticipatingStatesResponse>({
        query: pdmpNonParticipatingStatesQuery,
      })
      .pipe(map(mapToPdmpNonParticipatingStates));
  }

  private markAsReadyToSign = (cartId: number): Observable<boolean> =>
    this.apollo
      .use('onelife')
      .mutate({
        mutation: markRxCartReadyToSignMutation,
        variables: { rxCartId: cartId },
      })
      .pipe(map(mapToRxCartReadyToSignResult));

  private getPharmacy$ = (pharmacyId: number) =>
    this.patientPharmacySelectors.pharmacy(pharmacyId).pipe(
      filterTruthy(),
      map(({ pharmacy }) => pharmacy),
    );
}
