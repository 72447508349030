<div class="user-info-popover"
     data-cy="user-info-popover"
     *ngIf="userInfo$ | async as user; else loading">
  <div om-layout="vertical"
       om-layout-gutter
       om-layout-align="start"
       class="user-info-card">
    <div om-layout
         om-layout-align="left top">
      <omg-profile-image-bubble class="user-info-avatar"
                                [ngClass]="providerDeparted ? 'user-avatar-departed' : ''"
                                [url]="user.profileImageUrl"
                                [initials]="user.initials"
                                [colorCombo]="user.id"
                                [size]="large"></omg-profile-image-bubble>
      <div om-layout="vertical">
        <div om-layout
             om-layout-align="start center">
          <div class="user-info-name">{{ user.firstName }} {{ user.lastName }}</div>
          <div class="user-info-role"
               data-cy="user-info-role">{{ roleName }}</div>
        </div>
        <div>{{ user.suffix }}</div>
        <div *ngIf="user.providerPronoun && user.providerPronoun?.name !== 'Unspecified'">{{user.providerPronoun?.name}}
        </div>
        <div *ngIf="user.workSchedule?.returningOn && !providerDeparted"
             om-layout
             om-layout-align="start center"
             data-cy="returning-on">
          <svg width="16"
               height="15"
               viewBox="0 0 16 15"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 2.5C10.4336 2.5 9.44922 2.82812 8.68359 3.34766C8.19141 1.87109 6.49609 0.75 4.47266 0.75C2.25781 0.75 0.425781 2.0625 0.125 3.73047C0.0703125 4.00391 0.289062 4.25 0.617188 4.25H1.875L2.75 2.9375L3.65234 4.76953C3.54297 4.85156 3.46094 4.93359 3.35156 5.01562C1.79297 6.57422 1.4375 8.78906 2.39453 10.2109C2.55859 10.4297 2.88672 10.457 3.10547 10.2383L6.52344 6.82031C6.57812 9.52734 5.94922 12.1797 5.62109 13.3828C5.42969 14.0664 5.94922 14.75 6.66016 14.75H9.14844C9.66797 14.75 10.1328 14.3672 10.2148 13.8477C10.6797 10.9766 10.1602 7.88672 9.72266 6H11.5L12.375 4.6875L13.25 6H15.3828C15.6836 6 15.9023 5.75391 15.8477 5.48047C15.5469 3.8125 13.7148 2.5 11.5 2.5ZM8.95703 13.4375H6.96094C7.34375 11.9609 8 8.98047 7.78125 6H8.38281C8.79297 7.69531 9.33984 10.7031 8.95703 13.4375Z"
                  fill="black"
                  fill-opacity="0.87" />
          </svg>
          &nbsp;Out of office until {{ user.workSchedule.returningOn | omgDate: '4y' }}
        </div>
        <div *ngIf="providerDepartureEnabled && user.workSchedule?.departureDate"
             om-layout
             om-layout-align="start center"
             data-cy="returning-on">
          <i class="fa fa-door-open"></i>
          <span *ngIf="!providerDeparted">&nbsp;Departing on
            {{ user.workSchedule.departureDate | omgDate: '4y' }}</span>
          <span *ngIf="providerDeparted">&nbsp;Departed on {{ user.workSchedule.departureDate | omgDate: '4y' }}</span>

        </div>
      </div>
    </div>
    <div class="user-info-location">
      <span>{{ user.serviceArea?.name }}</span>
      <span> - </span>
      <span>{{ user.defaultOffice?.name }}</span>
    </div>
    <omg-collapse *ngIf="user.workSchedule && !(providerDepartureEnabled && providerDeparted)"
                  (expandedChange)="toggleChevron()"
                  data-cy="work-schedule">
      <div omgCollapseToggle
           om-layout
           om-layout-align="start center">
        <i *ngIf="expanded"
           class="chevron pi pi-chevron-right"></i>
        <i *ngIf="!expanded"
           class="chevron pi pi-chevron-down"></i>
        <div class="trigger">Working days</div>
      </div>
      <omg-expanded>
        <div om-layout>
          <div class="day"
               [class.-out]="user.workSchedule.sundaysOff">S</div>
          <div class="day"
               [class.-out]="user.workSchedule.mondaysOff">M</div>
          <div class="day"
               [class.-out]="user.workSchedule.tuesdaysOff">T</div>
          <div class="day"
               [class.-out]="user.workSchedule.wednesdaysOff">W</div>
          <div class="day"
               [class.-out]="user.workSchedule.thursdaysOff">T</div>
          <div class="day"
               [class.-out]="user.workSchedule.fridaysOff">F</div>
          <div class="day"
               [class.-out]="user.workSchedule.saturdaysOff">S</div>
        </div>
      </omg-expanded>
    </omg-collapse>
  </div>
  <hr *ngIf="user.workSchedule && !(providerDepartureEnabled && providerDeparted)"
      class="solid" />
  <div *ngIf="user.workSchedule && !(providerDepartureEnabled && providerDeparted)"
       om-layout="horizontal"
       om-layout-align="space-between center"
       class="bottom-bar">
    <a [href]="scheduleUrl"
       data-cy="schedule-link"
       target="_blank"
       title="Open schedule">
      <svg width="24"
           height="24"
           viewBox="0 0 24 24"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M20 3H19V1H17V3H7V1H5V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM20 21H4V8H20V21Z"
              fill="#0358B2" />
      </svg>
    </a>
    <a class="text-link"
       [href]="directoryUrl"
       data-cy="directory-link"
       target="_blank">View Directory</a>
  </div>
</div>

<ng-template #loading>
  <omg-feature-loading-spinner></omg-feature-loading-spinner>
</ng-template>
