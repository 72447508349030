import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, partition } from 'rxjs';
import { filter, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { DenialReason } from '@app/features/renewals/shared/renewals.type';
import {
  RxCartErrors,
  RxCartStatus,
  RxCartTypes,
  RxVerified,
} from '@app/features/rx-verification/shared/rx-verification.type';
import { PatientMedicationActions } from '@app/modules/medications/store/patient-medication.actions';
import { CheckoutDialogComponent } from '@app/modules/shared-rx/components/checkout-dialog/checkout-dialog.component';
import { CartPasswordState } from '@app/modules/shared-rx/shared-rx.type';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';

import { ChangeRxForm } from '../../shared/change-rx-form';
import { ChangeRx, ChangeRxTypes } from '../../shared/change-rx.type';
import { ChangeRxActions } from '../../store/change-rx.actions';
import { ChangeRxSelectors } from '../../store/change-rx.selectors';

@Component({
  selector: 'omg-change-rx-approval-dialog',
  templateUrl: './change-rx-approval-dialog.component.html',
  styleUrls: ['./change-rx-approval-dialog.component.scss'],
})
export class ChangeRxApprovalDialogComponent implements OnInit {
  readonly cartType: RxCartTypes = RxCartTypes.changeRx;

  patient$: Observable<Patient>;
  changeRx$: Observable<ChangeRx>;
  providerName$: Observable<string>;
  pediatricVitalsEnabled$: Observable<boolean>;

  changeRxTypes = ChangeRxTypes;

  changeRxCartStatus: RxCartStatus = {
    allReadyToSignItemsConfirmed: true,
    allHandwrittenItemsConfirmed: true,
    requireTwoFactorCheckout: false,
  };

  errorState = new BehaviorSubject(<RxCartErrors>{
    passwordVerified: false,
    invalidPassword: null,
    hasUnknownError: false,
    validationError: null,
  });

  errorState$: Observable<CartPasswordState> = this.errorState.asObservable();

  constructor(
    public dialogRef: DialogRef<CheckoutDialogComponent>,
    private patientSelectors: PatientSelectors,
    @Inject(OMG_DIALOG_DATA)
    public data: {
      form: ChangeRxForm;
      isApproved: boolean;
      reasonForDenial?: DenialReason;
    },
    private changeRxSelectors: ChangeRxSelectors,
    private patientMedicationActions: PatientMedicationActions,
    private actions: ChangeRxActions,
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.patient$ = this.patientSelectors.patient;
    this.changeRx$ = this.changeRxSelectors
      .getById(this.data.form.changeRx.id)
      .pipe(
        tap(changeRx => {
          if (this.isApproved) {
            this.changeRxCartStatus.allReadyToSignItemsConfirmed =
              !changeRx.rxChangeRequest?.require2Fa || this.pdmpEnabled;
            this.changeRxCartStatus.requireTwoFactorCheckout =
              changeRx.rxChangeRequest?.require2Fa;
          }
        }),
      );
    this.pediatricVitalsEnabled$ = this.changeRx$.pipe(
      map(changeRx => !!changeRx.rxChangeRequest),
    );
  }

  get isApproved() {
    return this.data.isApproved;
  }

  get reasonForDenial() {
    return this.data.reasonForDenial;
  }

  get isLoading$() {
    return this.changeRxSelectors.loading;
  }

  private get pdmpEnabled() {
    return this.launchDarklyService.variation(FeatureFlagNames.medsPDMP, false);
  }

  onReadyToSign(isReadyToSign: boolean) {
    if (isReadyToSign) {
      this.actions.markReadyToSign(this.data.form.changeRx.id);
    }
    this.changeRxCartStatus.allReadyToSignItemsConfirmed = isReadyToSign;
  }

  close() {
    this.dialogRef.close();
  }

  send({ password, token }: RxVerified) {
    this.handleSendResponse();

    this.data.isApproved
      ? this.data.form.approveChanges(password, token)
      : this.data.form.denyChanges(password, this.data.reasonForDenial);
  }

  private handleSendResponse() {
    const [error$, success$]: Observable<RxCartErrors>[] = partition(
      this.isLoading$.pipe(
        skipWhile(loading => !loading), // Wait for loading to start
        filter(loading => !loading),
        switchMap(() => this.changeRxSelectors.error),
      ),
      Boolean,
    );

    error$
      .pipe(
        tap(error => this.errorState.next({ ...this.errorState, ...error })),
        take(1),
      )
      .subscribe();

    success$
      .pipe(
        tap(() => this.close()),
        switchMap(() => this.patientSelectors.patientId),
        tap(patientId => {
          this.patientMedicationActions.loadPatientMedications(patientId);
          this.router.navigateByUrl(
            `/patients/${patientId}/chart/renewals/list`,
          );
        }),
        take(1),
      )
      .subscribe();
  }
}
